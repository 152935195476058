import React, { useCallback, useEffect, useState } from 'react';
import FormItem from './FormItem';
import { passwordRule } from '../../utils/validationRules';
import { Col, Form, message, Row, Upload } from 'antd';

import Button from '../../UI/buttons/Button';
import { confirmClientInvitation } from '../../api/client';
import { loginUserRequest } from '../../api/user';
import axios from 'axios';
import { loginUser } from '../../store/userSlice';
import { LOGIN, ONBOARD } from '../../constants/routes';
import { UrlParams } from '../../pages/InvitationConfirm';
import { Link, useHistory, useParams } from 'react-router-dom';
import { useAppDispatch } from '../../store/hooks';
import { ErrorMessage } from '../auth/LoginForm';
import InputPassword from '../../UI/inputs/InputPassword';
import styled from 'styled-components';
import { updateRecruiter } from '../../api/recruiter';
import FormTitle from '../auth/FormTitle';
import { useTranslation } from 'react-i18next';
import PersonalInfoRecruiter from './shared/PersonalInfoRecruiter';
import Input from '../../UI/inputs/Input';
import { ReactComponent as EditIcon } from '../../icons/edit.svg';
import { ReactComponent as JobIcon } from '../../icons/jobs.svg';
import { RcFile } from 'antd/lib/upload';

const StyledRow = styled(Row)`
  margin-bottom: 1rem;
`;
const StyledForm = styled(Form)`
  margin-top: 2.5rem;
  width: 100%;
`;
const PasswordHint = styled.p`
  font-size: 0.625rem;
  color: #627d95;
  margin: 0.5rem 0 1.5rem;
`;
const CompanyLogo = styled.div`
  width: 5rem;
  height: 5rem;
  border-radius: 50%;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  background-size: cover;
  background: #627d95 no-repeat center;
  &.bg-white {
    background: #ffffff no-repeat center;
  }
  & img {
    display: block;
    width: 100%;
    height: 100%;
    object-fit: contain;
    object-position: center;
    border-radius: 50%;
  }
`;
const StyledIcon = styled(JobIcon)`
  color: #aebeca;
  width: 3rem;
  height: 3rem;
`;
const ImageUploadButton = styled.div`
  border-radius: 0.25rem;
  background-color: ${({ theme }) => theme.lozengeColor};
  width: 1.5rem;
  height: 1.5rem;
  display: flex;
  align-items: center;
  justify-content: center;
  color: ${({ theme }) => theme.primaryColor};
  position: absolute;
  bottom: 2px;
  right: 0;
  z-index: 9;
`;
const StyledUpload = styled(Upload)`
  & .ant-upload {
    display: flex;
    align-items: center;
  }
`;
const FormatHint = styled.div`
  font-size: 0.75rem;
  line-height: 1.33;
  text-align: left;
  color: #627d95;
  margin-left: 1rem;
`;
const RecruiterInvitationForm = ({ user }: any) => {
  const [showCompany, setShowCompany] = useState<boolean>(true);
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState('');
  const [phone, setPhone] = useState('');
  const [logo, setLogo] = useState<string>(user?.image || '');
  const [fileError, setFileError] = useState<string>('');
  const { uid, token }: UrlParams = useParams();
  const dispatch = useAppDispatch();
  const history = useHistory();
  const [form] = Form.useForm();
  const [t] = useTranslation();
  const handleValueChange = (e: any) => {
    if (e.recruiter_type) {
      if (e.recruiter_type === 'freelance') {
        setShowCompany(false);
      } else {
        setShowCompany(true);
      }
    }
  };
  const handleUploadFile = useCallback(
    (file: RcFile): boolean => {
      if (file.type === 'image/png' || file.type === 'image/jpeg') {
        setFileError('');
        new Promise((resolve, reject) => {
          const reader = new FileReader();
          reader.readAsDataURL(file);
          reader.onload = () => {
            const base64 = reader.result;
            if (base64) {
              setLogo(base64?.toString());
            }
          };
          reader.onerror = (error) => reject(error);
        });
      } else {
        setFileError(t('FILE_FORMAT_VALIDATION'));
      }

      return false;
    },
    [t],
  );
  const onFinish = (values: any) => {
    setLoading(true);
    if (logo.startsWith('http')) {
      delete values.image;
    } else if (!logo) {
      delete values.image;
    } else {
      values.image = values.image.file;
    }
    const info = { ...values };
    const formData = new FormData();
    Object.keys(info).forEach((el) => {
      formData.append(el, values[el]);
    });
    formData.append('phone', phone);
    const confirmData = { uid, token, new_password: info.password };
    confirmClientInvitation(confirmData)
      .then(async ({ data }) => {
        message.success(data.detail, 3);
        delete info.password;
        const credentials = {
          email: values.email,
          password: values.password,
        };
        const user = await loginUserRequest(credentials);
        if (user) {
          axios.defaults.headers.common[
            'Authorization'
          ] = `Token ${user.access_token}`;
          updateRecruiter(formData)
            .then(async (res) => {
              axios.defaults.headers.common['Authorization'] = '';
              setLoading(false);
              const login = await dispatch(loginUser(credentials));
              if (login.payload?.user) {
                let loginedUser = login.payload?.user;
                // @ts-ignore
                localStorage.setItem("intercomID", process.env.REACT_APP_INTERCOM_ID);
                // @ts-ignore
                window.Intercom && window.Intercom('boot', {
                  app_id: process.env.REACT_APP_INTERCOM_ID,
                  email: loginedUser.email,
                  name: loginedUser.name,
                  user_id: loginedUser.id,
                  user_role: loginedUser.role,
                  user_has_default_tier: loginedUser.role === 'recruiter' ? loginedUser.has_default_tier : false,
                });
                history.push(ONBOARD);
              }
            })
            .catch((e) => {
              setLoading(false);
              e.response?.data.message
                ? setError(e.response?.data.message)
                : setError('Something went wrong');
            });
        } else {
          setLoading(false);
        }
      })
      .catch((e) => {
        setLoading(false);
        e.response?.data.message
          ? setError(e.response?.data.message)
          : setError('Something went wrong');
      });
  };
  useEffect(() => {
    if (user) {
      form.setFieldsValue({
        name: user.name,
        title: user.title,
        company: user.company?.name,
        recruiter_type: user.recruiter_type,
        email: user.email,
        username: user.email,
        phone: user.phone,
      });
      setPhone(user.phone);
    }
    user && user.recruiter_type === 'freelance' && setShowCompany(false);
  }, [user, form]);

  return (
    <>
      <FormTitle
        text={
          <>
            {t('PERSONAL_INFO_TITLE')}{' '}
            <span>
              {t('HAVE_ACCOUNT_QUESTION')}{' '}
              <Link to={LOGIN}>{t('LOG_IN_TEXT')}</Link>
            </span>
          </>
        }
      />
      <StyledForm
        scrollToFirstError={true}
        layout="vertical"
        name="recruiter-invite"
        onFinish={onFinish}
        onValuesChange={handleValueChange}
        form={form}
      >
        {error && <ErrorMessage message={error} type="error" />}
        <FormItem label={t('PROFILE_IMAGE')} name="image">
          <StyledUpload
            accept="image/png, image/jpeg, image/jpg"
            beforeUpload={handleUploadFile}
            showUploadList={false}
          >
            <CompanyLogo className={logo ? 'bg-white' : ''}>
              {logo ? <img src={logo} alt="" /> : <StyledIcon />}
              <ImageUploadButton>
                <EditIcon />
              </ImageUploadButton>
            </CompanyLogo>
            <FormatHint>PNG or JPG</FormatHint>
          </StyledUpload>
        </FormItem>
        {fileError && (
          <div className="ant-form-item-explain ant-form-item-explain-error">
            <div role="alert">{fileError}</div>
          </div>
        )}
        <PersonalInfoRecruiter
          showCompany={showCompany}
          isOnboarding={true}
          setPhone={setPhone}
          phone={phone}
          form={form}
          city={user?.city?.id}
        />
        <StyledRow>
          <Col
            xs={{ span: 24 }}
            sm={{ span: 11 }}
            md={{ span: 24 }}
            lg={{ span: 11 }}
          >
            <FormItem
              label={t('')}
              name="email"
              initialValue={user.email}
              className="hidden-input"
            >
              <Input type={'email'} />
            </FormItem>
            <FormItem
              label={t('PASSWORD_LABEL')}
              name="password"
              rules={passwordRule(t)}
              className="no-margin"
            >
              <InputPassword />
            </FormItem>
            <PasswordHint>{t('PASSWORD_HINT')}</PasswordHint>
          </Col>
        </StyledRow>

        <FormItem>
          <Button type="primary" htmlType="submit" loading={loading}>
            {t('SIGN_UP_TITLE')}
          </Button>
        </FormItem>
      </StyledForm>
    </>
  );
};

export default RecruiterInvitationForm;
