import React, { ReactNode, useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import styled from 'styled-components';
import { Drawer } from 'antd';
import Navigation from './Navigation';
import { useTranslation } from 'react-i18next';
import Button from '../../UI/buttons/Button';

interface LayoutProps {
  children: ReactNode;
}

const LayoutBody = styled.section`
  padding: 0 0 0 4rem;
  background: ${({ theme }) => theme.lozengeColor};
  min-height: 100vh;
  &.mobile-body {
    @media (max-width: 768px) {
      padding: 0;
    }
  }
  &.onboard-route {
    padding: 0;
  }
  @media (max-width: 576px) {
    padding: 0 0 0 4rem;
  }
`;
const NotifyText = styled.p`
  font-size: 0.875rem;
  line-height: 1.71;
  text-align: left;
  color: #061c2e;
  margin-bottom: 1.5rem;
`;
const StyledDrawer = styled(Drawer)`
  display: none;
  @media (max-width: 768px) {
    display: initial;
  }
`;
const Layout = ({ children, ...rest }: LayoutProps) => {
  const { pathname } = useLocation();
  const isMessagePage = pathname.includes('/messages');
  const notifiedForMobile = localStorage.getItem('mobile-notified');
  const [isNotified, setIsNotified] = useState(false);
  const { t } = useTranslation();
  const isOnboardRoute = pathname.includes('/onboard') || pathname.includes('/terms');

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);
  useEffect(() => {
    if (!notifiedForMobile) {
      setIsNotified(false);
    } else {
      setIsNotified(true);
    }
  }, [notifiedForMobile]);
  return (
    <>
      {isOnboardRoute ? <></> : <Navigation />}
      <LayoutBody
        className={`
        ${isMessagePage ? 'mobile-body' : ''}
        ${isOnboardRoute ? 'onboard-route' : ''}
        `}
      >
        {children}
        <StyledDrawer
          height="auto"
          closable={true}
          visible={!isNotified}
          placement={'top'}
          getContainer={false}
        >
          <NotifyText>{t('NON_OPTIMIZED_TEXT')}</NotifyText>
          <Button
            type="primary"
            onClick={() => {
              setIsNotified(true);
              localStorage.setItem('mobile-notified', '1');
            }}
          >
            {t('OK_TEXT')}
          </Button>
        </StyledDrawer>
      </LayoutBody>
    </>
  );
};

export default Layout;
