import React, { useEffect, useState } from 'react';
import { Route, RouteProps, useHistory } from 'react-router-dom';
import { DASHBOARD } from '../../constants/routes';
import { getToken } from '../../utils/localStorage';
import axios from 'axios';
import Error from '../../pages/Error';
interface PublicRouteProps extends RouteProps {
  component: any;
}

// doing this instead of react-router-dom's Redirect
// to avoid some interesting race conditions...
const SimpleRedirect = ({ to }: { to: string }) => {
  const history = useHistory();
  useEffect(() => {
    history.push(to);
  }, [history, to]);
  return null;
}

const PublicRoute = (props: PublicRouteProps) => {
  const { component: Component, ...rest } = props;
  const [error, setError] = useState(false);
  const token = getToken('access_token');
  axios.interceptors.response.use((response) => response, (error) => {
    if (error.response.status >= 500) {
      setError(true)
    } else {
      return Promise.reject(error);
    }
  });
  return (
    <Route
      {...rest}
      render={(props) =>
        error ? <Error /> : token ? <SimpleRedirect to={DASHBOARD} /> : <Component {...props} />
      }
    />
  );
};

export default PublicRoute;
