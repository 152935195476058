import styled from 'styled-components';
import { useTranslation } from 'react-i18next';

import { ReactComponent as WalletIcon } from '../../icons/wallet2.svg';
import { ReactComponent as CalendarIcon } from '../../icons/calendar.svg';
import { ReactComponent as ChatIcon } from '../../icons/chat.svg';
import { ReactComponent as Clock } from '../../icons/clock3.svg';
import { ReactComponent as DownloadIcon } from '../../icons/download.svg';

import TextWithIcon from '../jobs/singleJob/TextWithIcon';
import { CompanyAgreement } from '../../types';
import getRecruiterFeeSummary, { RecruiterFeeSummarySubject } from '../../utils/getRecruiterFeeSummary';
import { formatJobFeeFields, JobFeeFormatSubject } from '../../utils/formatJobFeeFields';
import {
  ActionLink,
  ActionWrapper,
} from '../recruitment_management/RecruiterEditAgreement';
import useCurrency from '../../hooks/useCurrency';

type RecruiterTermsFeeSectionProps = {
  agreementProvider: JobFeeFormatSubject & RecruiterFeeSummarySubject & Pick<CompanyAgreement, 'fee_currency'>,
};

const StyledText = styled(TextWithIcon)`
  flex: 1 1 250px;
  flex-grow: 0;
  & span {
    white-space: pre-line;
    color: #627d95;
  }
  & path {
    stroke: ${({ theme }) => theme.primaryColor};
  }
`;

const RecruiterTermsFeeSection = ({ agreementProvider }: RecruiterTermsFeeSectionProps) => {
  const [t] = useTranslation();
  const currency = useCurrency(agreementProvider.fee_currency);
  const recruiterFeeSummary = getRecruiterFeeSummary(t, currency, agreementProvider, false);
  const extendedFeeTerms = formatJobFeeFields(agreementProvider, false);

  return (
    <div style={{ display: 'flex', flexWrap: 'wrap', width: '100%', gap: '1rem 0.5rem' }}>
      {!!recruiterFeeSummary.recruiterFee && (
        <StyledText icon={<WalletIcon />} title={t('RECRUITER_COMPENSATION')}>
          <span>
            {recruiterFeeSummary.fallbackActive &&
              !recruiterFeeSummary.recruiterFee.includes('-') ? (
              <>
                <p>
                  <a href="https://help.hireport.nl/en/articles/7973723-what-fee-can-i-expect-after-a-placement">
                    {t('TIERED_FEES')}
                  </a>
                  : {recruiterFeeSummary.recruiterFee}
                </p>
              </>
            ) : (
              recruiterFeeSummary.recruiterFee
            )}
          </span>
        </StyledText>
      )}
      {!!extendedFeeTerms.payment_terms && (
        <StyledText icon={<CalendarIcon />} title={'Permanent ' + t('JOB_PAYMENT_TERMS')}>
          <span>
            {extendedFeeTerms.payment_terms} {t('DAYS')}
          </span>
        </StyledText>
      )}
      {extendedFeeTerms.guarantee_period !== null &&
        (extendedFeeTerms.guarantee_period ?? 0) > 0 && (
          <StyledText icon={<CalendarIcon />} title={'Permanent ' + t('GUARANTEE_PERIOD')}>
            <span>
              {extendedFeeTerms.guarantee_period} {t('MONTHS')}
            </span>
          </StyledText>
        )}
      {(
        <>
          {!!extendedFeeTerms.restitution && (
            <StyledText icon={<CalendarIcon />} title={'Permanent ' + t('JOB_RESTITUTION')}>
              <span>
                {extendedFeeTerms.restitution} {t('PERCENTAGE')}
              </span>
            </StyledText>
          )}
          {!!extendedFeeTerms.replacement_time && (
            <StyledText icon={<CalendarIcon />} title={'Permanent ' + t('JOB_REPLACEMENT_TIME')}>
              <span>
                {extendedFeeTerms.replacement_time} {t('MONTHS')}
              </span>
            </StyledText>
          )}
          {(
            extendedFeeTerms.exclusivity_for_replacement === true ||
            extendedFeeTerms.exclusivity_for_replacement === false) && (
              <StyledText
                icon={<Clock />}
                title={'Permanent ' + t('JOB_EXCLUSIVITY_FOR_REPLACEMENT')}
              >
                <span>
                  {extendedFeeTerms.exclusivity_for_replacement === true
                    ? t('YES')
                    : t('NO')}
                </span>
              </StyledText>
            )}
          {!!extendedFeeTerms.restitution_payment_terms && (
            <StyledText
              icon={<CalendarIcon />}
              title={'Permanent ' + t('JOB_RESTITUTION_PAYMENT_TERMS')}
            >
              <span>
                {extendedFeeTerms.restitution_payment_terms} {t('DAYS')}
              </span>
            </StyledText>
          )}
          {extendedFeeTerms.payment_terms_start ? (
            <StyledText
              icon={<CalendarIcon />}
              title={'Permanent ' + t('JOB_PAYMENT_TERMS_START')}
            >
              <span>{extendedFeeTerms.payment_terms_start}</span>
            </StyledText>
          ) : (
            <StyledText
              icon={<CalendarIcon />}
              title={'Permanent ' + t('JOB_PAYMENT_TERMS_START')}
            >
              <span>{t('AFTER_CANDIDATE_START_DATE')}</span>
            </StyledText>
          )}
          {!!extendedFeeTerms.custom_restitution_agreement && (
            <StyledText
              icon={<CalendarIcon />}
              title={'Permanent ' + t('JOB_CUSTOM_RESTITUTION_AGREEMENT')}
            >
              <span>{extendedFeeTerms.custom_restitution_agreement}</span>
            </StyledText>
          )}
        </>
      )}
      <>
        <div style={{ display: 'flex', flexWrap: 'wrap' }}>
          <StyledText icon={<CalendarIcon />} title={'CTP ' + t('COMPANY_AGREEMENT_ET_GDA_SHORT')}>
            <span>
              {extendedFeeTerms.et_gda_ctp || '-'}
            </span>
          </StyledText>
        </div>
        <div style={{ display: 'flex', flexWrap: 'wrap' }}>
          <StyledText icon={<CalendarIcon />} title={'CTP ' + t('COMPANY_AGREEMENT_ET_CAO_SHORT')}>
            <span>
              {extendedFeeTerms.et_cao_ctp || '-'}
            </span>
          </StyledText>
        </div>
      </>
      <div style={{ display: 'flex', flexWrap: 'wrap', width: '100%' }}>
        <StyledText icon={<CalendarIcon />} title={t('TRANSACTION_FEE_TO_COMPANY_PERCENT')}>
          <span>
            {extendedFeeTerms.transaction_fee_to_company_percent || '0'}%
          </span>
        </StyledText>
      </div>
      <div style={{ display: 'flex', flexWrap: 'wrap' }}>
        {!!extendedFeeTerms.other_information && (
          <StyledText icon={<ChatIcon />} title={t('JOB_OTHER_INFORMATION')}>
            <span>{extendedFeeTerms.other_information}</span>
          </StyledText>
        )}
      </div>
      <div style={{ width: '100%', display: 'flex' }}>
        {!!extendedFeeTerms.contract && (
          <ActionWrapper>
            <ActionLink
              href={extendedFeeTerms.contract as string}
              target="_blank"
              rel="noreferrer"
            >
              <DownloadIcon />
              {t('COMPANY_AGREEMENT_DOWNLOAD_CONTRACT')}
            </ActionLink>
          </ActionWrapper>
        )}
      </div>
    </div>
  );
};

export default RecruiterTermsFeeSection;
