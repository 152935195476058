import { Col, Form, Row, Modal } from "antd";
import { Card, List } from "antd";
import React, { useState, useEffect } from "react";
import { DASHBOARD } from '../../constants/routes';
import { acceptRecruiterTerms, declineRecruiterTerms } from '../../api/recruiter';
import { useHistory } from 'react-router-dom';
import { Trans, useTranslation } from "react-i18next";
import styled from "styled-components";
import Loader from "../../UI/Loader";
import Button from "../../UI/buttons/Button";
import CheckboxInput from "../../UI/inputs/CheckboxInput";
import { ReactComponent as WarningIcon } from "../../icons/alert-icon.svg";
import { ReactComponent as BulletIcon } from "../../icons/arrow-right.svg";
import { ReactComponent as CheckIcon } from "../../icons/checked.svg";
import FormTitle from "../auth/FormTitle";
import FormItem from "../form/FormItem";
import type { Company, CompanyAgreement, User } from '../../types';
import { getClientCompanyById } from '../../api/company';
import { getRecruiterCompanyAgreement } from '../../api/recruiter';
import RecruiterTermsFeeSection from './RecruiterTermsFeeSection';

const EXAMPLE_TRANSACTION = 10000;

const FormActions = styled.div`
  padding: 1rem;
  border-radius: ${({ theme }) => theme.radius};
  background-color: ${({ theme }) => theme.lozengeColor};
  text-align: center;

  div {
    width: 100%;
    display: flex;
    justify-content: center;
    gap: 1rem;
    margin-bottom: 1rem;
  }

  button {
    margin-top: 0 !important;
  }
`;
const SubTitle = styled.h5`
  font-size: 1.25rem;
  font-weight: 700;
  margin: 1rem 0;
  color: #061c2e;
`;
const StyledList = styled.div`
  .ant-list-item {
    border-bottom: 0;
    padding: 0.1rem 0;
  }
`;
const StyledListIcon = styled(BulletIcon)`
  margin-top: 0.2rem;
  path {
    stroke: #34ce72;
  }
`;
const StyledBenefitIcon = styled(CheckIcon)`
  margin-top: 0.1rem;
  path {
    stroke: #34ce72;
  }
`;
const StyledCheckBoxItem = styled(CheckboxInput)`
  width: 100%;
  margin-bottom: 0.5rem;
  padding-right: 1rem;
`;
const StyledRow = styled(Row)`
  margin-top: 1rem;
`;
const StyledWarning = styled.div`
  margin-top: 2.5rem;

  & p {
    margin-bottom: 0;
  }
  & svg {
    width: 2rem;
    margin-right: 1rem;
  }
  & .ant-card-body {
    display: flex;
  }
`;
const StyledSectionTitle = styled.p`
  margin-bottom: 1rem;
`;

const StyledModal = styled(Modal)`
  & .ant-modal-content {
    padding: 32px;
  }
  &.video{
    .ant-modal-content {
      padding: 0;
    }
    .ant-modal-body {
      display: flex;
    }
    iframe {
      aspect-ratio: 16 / 9;
    }
  }
  & .ant-modal-header {
    border-bottom: none;
    padding: 0;
    margin-bottom: 16px;
    & div {
      font-size: 20px;
      font-weight: bold;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.2;
      letter-spacing: normal;
      text-align: left;
      color: #061c2e;
    }
  }
  & .ant-modal-body {
    padding: 0;
    & p {
      font-size: 14px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.71;
      letter-spacing: normal;
      text-align: left;
      color: #061c2e;
    }
  }
  & .ant-modal-footer {
    padding: 0;
    display: flex;
    border-top: none;
  }
`;

type RecruiterOnboardProps = {
  user: User,
  isOnboard: boolean;
};

const RecruiterOnboard = ({ user }: RecruiterOnboardProps) => {
  const { t } = useTranslation();
  const history = useHistory();
  const [pageLoad, setPageLoad] = useState(true);
  const [form] = Form.useForm();
  const [unagreedAgreementIds, setUnagreedAgreementIds] = useState<string[]>(user.unagreed_agreement_ids ?? []);
  const [invitingCompany, setInvitingCompany] = useState<Company>();
  const [companyAgreement, setCompanyAgreement] = useState<CompanyAgreement>();
  const [feeModalOpen, setFeeModalOpen] = useState(false);
  const [videoModalOpen, setVideoModalOpen] = useState(false);

  const firstAgreementId = unagreedAgreementIds.find(item => !!item)

  const onFinish = async (values: any) => {
    if (!firstAgreementId)
      return;
    try {
      if (values.terms) await acceptRecruiterTerms(firstAgreementId, values);
      else await declineRecruiterTerms(firstAgreementId, values);
      const remainingAgreementIds = unagreedAgreementIds.filter(id => id !== firstAgreementId);
      if (remainingAgreementIds.length <= 0) {
        history.push(DASHBOARD);
        return;
      }
      setUnagreedAgreementIds(remainingAgreementIds);
      form.resetFields();
    } catch (error) {
      console.error('Error processing invitation:', error)
    }
  };

  useEffect(() => {
    async function fetchData() {
      if (!firstAgreementId)
        return;
      const companyAgreement = await getRecruiterCompanyAgreement(firstAgreementId);
      if (!companyAgreement)
        return;
      const invitingCompany = await getClientCompanyById(companyAgreement.data.company);
      return {
        invitingCompany,
        companyAgreement,
      }
    }
    fetchData().then((res) => {
      if (!res) return;
      setInvitingCompany(res.invitingCompany.data);
      setCompanyAgreement(res.companyAgreement.data);
      setPageLoad(false);
    });
  }, [firstAgreementId])

  const transactionFeePercent = invitingCompany?.transaction_fee_per_mil ? invitingCompany.transaction_fee_per_mil / 10 : 0;
  const companyFeePortion = companyAgreement?.transaction_fee_to_company_percent ?? 0;
  const recruiterFeePortion = 100 - companyFeePortion;
  const effectiveFeePercent = (transactionFeePercent * recruiterFeePortion) / 100;
  const exampleFeeAmount = Math.round(EXAMPLE_TRANSACTION * effectiveFeePercent / 100);

  return pageLoad ? (
    <Loader spinning={pageLoad} />
  ) : (
    <Form
      scrollToFirstError={true}
      layout="vertical"
      name="client-onboard"
      onFinish={onFinish}
      form={form}
    >
      <FormTitle text={t('RECRUITERS_INVITE_TITLE', { company: invitingCompany?.name })} />
      <StyledRow>
        <Col>
          {t('RECRUITERS_INVITE_LEADIN', { company: invitingCompany?.name })}<br />
          <Trans
            i18nKey="RECRUITERS_INVITE_TERMS_INSTRUCTIONS_VIDEO"
            // eslint-disable-next-line jsx-a11y/anchor-is-valid
            components={{ a: <a href="#" onClick={() => setVideoModalOpen(true)}>video</a> }}
          />
        </Col>
      </StyledRow>
      <StyledRow>
        <Col xs={{ span: 24 }}>
          <SubTitle>{t('RECRUITERS_INVITE_PARTNERSHIP_TITLE')}</SubTitle>
          <StyledList>
            <List
              itemLayout="horizontal"
              dataSource={[
                { title: t('RECRUITERS_INVITE_PARTNERSHIP_ITEM_1') },
                { title: t('RECRUITERS_INVITE_PARTNERSHIP_ITEM_2', { company: invitingCompany?.name }) },
                { title: t('RECRUITERS_INVITE_PARTNERSHIP_ITEM_3') }
              ]}
              renderItem={item => (
                <List.Item>
                  <List.Item.Meta
                    avatar={<StyledListIcon />}
                    title={item.title}
                  />
                </List.Item>
              )}
            />
          </StyledList>
        </Col>
      </StyledRow>
      {invitingCompany?.transaction_fee_enabled &&
        <StyledRow>
          <Col xs={{ span: 24 }}>
            <SubTitle>{t('RECRUITERS_INVITE_TRANSACTION_TITLE')}</SubTitle>
            <StyledList>
              <List
                itemLayout="horizontal"
                dataSource={[
                  { title: t('RECRUITERS_INVITE_TRANSACTION_ITEM_1', { transaction_fee: transactionFeePercent }) },
                  ...(companyAgreement?.transaction_fee_to_company_percent !== 0
                    ? [{ title: t('RECRUITERS_INVITE_TRANSACTION_ITEM_2', { recruiter_fee: recruiterFeePortion, company_fee: companyFeePortion }) }]
                    : []),
                  { title: t('RECRUITERS_INVITE_TRANSACTION_ITEM_3', { example_fee_amount: exampleFeeAmount, example_fee_percentage: Math.round(effectiveFeePercent * 100) / 100 }) }
                ]}
                renderItem={item => (
                  <List.Item>
                    <List.Item.Meta
                      avatar={<StyledListIcon />}
                      title={item.title}
                    />
                  </List.Item>
                )}
              />
            </StyledList>
          </Col>
        </StyledRow>
      }
      <StyledRow>
        <Col xs={{ span: 24 }}>
          <SubTitle>{t('RECRUITERS_INVITE_INVOICING_TITLE')}</SubTitle>
          <StyledList>
            <List
              itemLayout="horizontal"
              dataSource={[
                { title: t('RECRUITERS_INVITE_INVOICING_ITEM_1') },
                { title: t('RECRUITERS_INVITE_INVOICING_ITEM_2', { recruiter_fee: 1, company_fee: 3 }) },
                { title: t('RECRUITERS_INVITE_INVOICING_ITEM_3') }
              ]}
              renderItem={item => (
                <List.Item>
                  <List.Item.Meta
                    avatar={<StyledBenefitIcon />}
                    title={item.title}
                  />
                </List.Item>
              )}
            />
          </StyledList>
        </Col>
      </StyledRow>
      <StyledRow>
        <Col xs={{ span: 24 }}>
          <StyledSectionTitle>{t('RECRUITERS_INVITE_TERMS_TITLE', { company: invitingCompany?.name })}</StyledSectionTitle>
          <FormItem name="terms" valuePropName="checked">
            <StyledCheckBoxItem value={false}>
              {t('RECRUITERS_INVITE_TERMS_LABEL')}
            </StyledCheckBoxItem>
          </FormItem>
        </Col>
      </StyledRow>
      <FormActions>
        <div>
          <Button
            type="primary"
            onClick={() => {
              form.setFieldsValue({ terms: true });
              form.submit();
            }}
          >
            {t("RECRUITERS_INVITE_TERMS_ACCEPT")}
          </Button>
          <Button
            type="dashed"
            onClick={() => {
              form.setFieldsValue({ terms: false });
              form.submit();
            }}
            style={{
              color: '#eb5757',
              borderColor: '#eb5757'
            }}
          >
            {t("RECRUITERS_INVITE_TERMS_DECLINE")}
          </Button>
        </div>
        <i>
          <Trans
            i18nKey="RECRUITERS_INVITE_TERMS_CONTRACT_DETAILS"
            // eslint-disable-next-line jsx-a11y/anchor-is-valid
            components={{ a: <a href="#" onClick={() => setFeeModalOpen(true)}>fees</a> }}
          />
        </i>
      </FormActions>
      <StyledWarning>
        <Card>
          <WarningIcon />
          <p>
            <Trans
              i18nKey="RECRUITERS_INVITE_TERMS_IMPORTANT"
              values={{ company: invitingCompany?.name }}
              components={{ b: <strong /> }}
            />
          </p>
        </Card>
      </StyledWarning>
      <StyledRow>
        <Col>
          <i>
            <Trans
              i18nKey="RECRUITERS_INVITE_PLATFORM_TERMS"
              components={{ a: <a href="http://hireport.nl/terms-conditions-agencies" rel="noreferrer" target="_blank">agreement</a> }}
            />
          </i>
        </Col>
      </StyledRow>

      {/* Modals */}
      {companyAgreement &&
        <StyledModal
          title={t('RECRUITERS_INVITE_TERMS_DIALOG_TITLE', { company_name: companyAgreement.company_name })}
          centered
          visible={feeModalOpen}
          onOk={() => setFeeModalOpen(false)}
          onCancel={() => setFeeModalOpen(false)}
          footer={null}
          width={580}
        >
          <RecruiterTermsFeeSection agreementProvider={{
            recruiter_company_agreement: companyAgreement,
            fee_currency: companyAgreement.fee_currency,
          }} />
        </StyledModal>
      }
      <StyledModal
        centered
        visible={videoModalOpen}
        onOk={() => setVideoModalOpen(false)}
        onCancel={() => setVideoModalOpen(false)}
        footer={null}
        width={'60vw'}
        className="video"
        destroyOnClose
      >
        <iframe
          width="100%"
          src="https://www.youtube.com/embed/QjrEutFNhsg?si=TZPq7WPER1Tyfk2F"
          title="HirePort Instruction video"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowFullScreen
        />
      </StyledModal>
    </Form>
  );
};

export default RecruiterOnboard;
