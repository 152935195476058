import { useTranslation } from 'react-i18next';
import Input from '../../UI/inputs/Input';
import SectionItem, { SectionTitle } from '../jobs/singleJob/SectionItem';
import { CompanyAgreement } from '../../types';
import FormItem from '../form/FormItem';
import SelectInput from '../../UI/inputs/Select';
import { ReactComponent as DownloadIcon } from '../../icons/download.svg';
import { ReactComponent as ArrowDownIcon } from '../../icons/arrow-down.svg';
import { ReactComponent as DeleteIcon } from '../../icons/delete.svg';
import { FEE_TYPES } from '../recruiter/AddCompanyRecruiterForm';
import { Col, Form, Row, Select, message, Upload, Space, Tabs } from 'antd';
import Button from '../../UI/buttons/Button';
import { useEffect, useState } from 'react';
import { patchRecruiterCompanyAgreement } from '../../api/recruiter';
import { useAppSelector } from '../../store/hooks';
import styled from 'styled-components';
import { RcFile } from 'antd/lib/upload';
import Tab from '../../UI/Tab';
import { useGetEmploymentTypeNameById } from '../../hooks/useEmploymentTypes';
import { PAYMENT_TERM_START_TYPES } from '../client/MasterContractForm';
import Textarea from '../../UI/inputs/Textarea';
import useCurrency from '../../hooks/useCurrency';
import CurrencySelect from '../form/shared/currencies/CurrencySelect';
import TransactionFeeFormItem from '../form/shared/TransactionFeeFormItem';

const { TabPane } = Tabs;

export const StyledUploader = styled(Upload)`
  display: block;
  & .ant-upload-select {
    width: 100%;
    border: 1px solid #aebeca;
    border-radius: ${({ theme }) => theme.radius};
    height: 3rem;
  }
`;

export const FileUploader = styled.div`
  display: flex;
  padding: 0.5rem 1rem;
  align-items: center;
  width: 100%;
  height: 100%;
`;

export const FileName = styled.p`
  font-size: 0.875rem;
  line-height: 1.43;
  color: #061c2e;
  margin: 0 0.5rem 0 0;
  width: 100%;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`;

export const UploadAction = styled.div`
  display: flex;
  align-items: center;
`;

export const DeleteFileIcon = styled(DeleteIcon)`
  color: #eb5757;
  cursor: pointer;
`;

export const ActionWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin-right: 0.5rem;
  margin-left: -0.5rem;
  @media (max-width: 992px) {
    width: 100%;
    justify-content: flex-start;
    flex-wrap: wrap;
  }
`;

export const ActionLink = styled.a`
  font-size: 0.75rem;
  font-weight: 800;
  line-height: 1.33;
  color: #627d95;
  display: flex;
  align-items: center;
  padding: 0.5rem;
  border: 1px solid #627d95;
  border-radius: 0.25rem;
  margin-left: 1rem;
  @media (max-width: 992px) {
    margin-bottom: 0.5rem;
  }
  & svg {
    min-width: 1rem;
    min-height: 1rem;
    width: 1rem;
    height: 1rem;
    margin-right: 0.5rem;
  }
  &:hover {
    color: #627d95;
  }
`;

const RecruiterEditAgreement = ({
  recruiterCompanyAgreementId,
  recruiterAgreement,
}: {
  recruiterCompanyAgreementId: string;
  recruiterAgreement: CompanyAgreement;
}) => {
  const { t } = useTranslation();
  const defaultCurrency = useCurrency(recruiterAgreement.fee_currency);
  const [currency, setCurrency] = useState(defaultCurrency);
  const [form] = Form.useForm();
  const { user } = useAppSelector((state) => state.user);

  const extendedRecruiterAgreementEnabled =
    user?.company?.extended_recruiter_agreement_enabled;
  const transactionFeeEnabled =
    user?.company?.transaction_fee_enabled || false;
  const [formValues, setFormValues] =
    useState<CompanyAgreement>(recruiterAgreement);
  const [submittable, setSubmittable] = useState(false);
  const [file, setFile] = useState(recruiterAgreement.contract ?? '');
  const [newFileUploading, setNewFileUploading] = useState(false);
  const getEmploymentTypeNameById = useGetEmploymentTypeNameById();

  const persistValues = async (values: any) => {
    try {
      const data = new FormData();
      if (extendedRecruiterAgreementEnabled) {
        data.append('payment_terms', String(values.payment_terms || ''));
        data.append('guarantee_period', String(values.guarantee_period || ''));
        data.append('restitution', String(values.restitution || ''));
        data.append(
          'restitution_payment_terms',
          String(values.restitution_payment_terms || ''),
        );
        data.append('replacement_time', String(values.replacement_time || ''));
        data.append(
          'exclusivity_for_replacement',
          String(values.exclusivity_for_replacement || ''),
        );
        data.append('et_details_freelance', String(values.et_details_freelance || ''));
        data.append('et_details_ctp', String(values.et_details_ctp || ''));
        data.append('et_gda_ctp', String(values.et_gda_ctp || ''));
        data.append('et_cao_ctp', String(values.et_cao_ctp || ''));
        data.append('transaction_fee_to_company_percent', String(values.transaction_fee_to_company_percent || 0));
        data.append('other_information', String(values.other_information || ''));
        data.append('payment_terms_start', String(values.payment_terms_start || ''));
        data.append(
          'custom_restitution_agreement',
          String(values.custom_restitution_agreement || ''),
        );
        if (values.contract?.file?.originFileObj) {
          data.append('contract', values.contract.file.originFileObj);
        } else {
          data.append('contract', '');
        }
      }

      data.append('compensation_type', String(values.compensation_type || ''));
      data.append(
        'compensation_type_tooltip',
        String(values.compensation_type_tooltip || ''),
      );
      data.append('fee_currency', String(values.fee_currency || ''));

      patchRecruiterCompanyAgreement(recruiterCompanyAgreementId, data);

      setSubmittable(false);
      setNewFileUploading(false);
      message.success(t('COMPANY_RECRUITER_AGREEMENT_UPDATED_SUCCESSFULLY'));
    } catch (error) {
      message.error(t('ERROR_UPDATING_COMPANY_RECRUITER_AGREEMENT'));
    }
  };

  const handleUploadFile = (file: RcFile): boolean => {
    setFile(file.name);
    setSubmittable(true);
    setNewFileUploading(true);
    return true;
  };

  const handleFileDelete = (e: React.MouseEvent<SVGSVGElement>) => {
    e.stopPropagation();
    setFile('');
    setSubmittable(true);
    form.setFieldsValue({ contract: undefined });
  };

  useEffect(() => {
    if (JSON.stringify(recruiterAgreement) !== JSON.stringify(formValues)) {
      setSubmittable(true);
    }
  }, [formValues, recruiterAgreement]);

  return (
    <>
      <Form
        scrollToFirstError={true}
        form={form}
        onFinish={persistValues}
        labelCol={{ span: 24 }}
        initialValues={{
          ...recruiterAgreement,
          exclusivity_for_replacement:
            recruiterAgreement.exclusivity_for_replacement === true
              ? 'Yes'
              : recruiterAgreement.exclusivity_for_replacement === false
                ? 'No'
                : null,
          compensation_type_tooltip:
            recruiterAgreement.compensation_type_tooltip
              ? Number(recruiterAgreement.compensation_type_tooltip)
              : null,
        }}
      >
        <Space direction="vertical" size="middle" style={{ display: 'flex' }}>
          <SectionItem title={t('RECRUITER_FEE_INFORMATION')}>
            <Row>
              <Col xs={{ span: 12 }} md={{ span: 8 }} xl={{ span: 8 }}>
                <FormItem
                  label={t('FEE_CURRENCY_FIELD_LABEL')}
                  name="fee_currency"
                >
                  <CurrencySelect
                    size="large"
                    onChange={(value) => {
                      setCurrency(currency.changeCurrency(value));
                      // !WB: why on earth is this needed – just to enable the save button??
                      setFormValues({ ...formValues, fee_currency: value })
                    }}
                  />
                </FormItem>
              </Col>
            </Row>
            <Tab defaultActiveKey="1" className="mt-5">
              <TabPane tab={t('FEE_ET_TYPE', { employmentType: getEmploymentTypeNameById('permanent') })} key="1">
                <Row gutter={10}>
                  <Col xs={{ span: 12 }} md={{ span: 8 }} xl={{ span: 8 }}>
                    <FormItem
                      name="compensation_type"
                      label="FEE STRUCTURE"
                      rules={[
                        { required: true, message: 'Please select a fee type!' },
                      ]}
                    >
                      <SelectInput
                        size="middle"
                        showSearch
                        optionFilterProp="children"
                        filterOption={true}
                        dropdownMatchSelectWidth={false}
                        className="custom-select"
                        suffixIcon={<ArrowDownIcon />}
                        onChange={(value) =>
                          setFormValues({ ...formValues, compensation_type: value })
                        }
                      >
                        {FEE_TYPES.map((fee, index) => (
                          <Select.Option value={fee.fee_value} key={index}>
                            {fee.fee_name}
                          </Select.Option>
                        ))}
                      </SelectInput>
                    </FormItem>
                  </Col>
                  <Col xs={{ span: 12 }} md={{ span: 8 }} xl={{ span: 8 }}>
                    <FormItem
                      name="compensation_type_tooltip"
                      label={t('FEE_DETAILS_FORM_LABEL')}
                      rules={[{ message: t('REQUIRED_FIELD') }]}
                    >
                      <Input
                        type="number"
                        maxLength={70}
                        style={{ height: '40px' }}
                        size="middle"
                        onChange={(e) =>
                          setFormValues({
                            ...formValues,
                            compensation_type_tooltip: Number(e.target.value),
                          })
                        }
                      />
                    </FormItem>
                  </Col>
                  <Col xs={{ span: 12 }} md={{ span: 8 }} xl={{ span: 8 }}>
                    <FormItem
                      name="guarantee_period"
                      label={t('COMPANY_AGREEMENT_GUARANTEE_PERIOD')}
                      rules={[{ required: false }]}
                    >
                      <Input
                        placeholder={t(
                          'COMPANY_AGREEMENT_GUARANTEE_PERIOD_PLACEHOLDER',
                        )}
                        type="number"
                        maxLength={70}
                        style={{ height: '40px' }}
                        size="middle"
                        onChange={(e) =>
                          setFormValues({
                            ...formValues,
                            guarantee_period: Number(e.target.value),
                          })
                        }
                      />
                    </FormItem>
                  </Col>
                </Row>
                {extendedRecruiterAgreementEnabled && (
                  <>
                    <SectionTitle>{t('RECRUITER_RESTITUTION')}</SectionTitle>
                    <Row gutter={10}>
                      <Col xs={{ span: 12 }} md={{ span: 8 }} xl={{ span: 8 }}>
                        <FormItem
                          name="restitution"
                          label={t('COMPANY_AGREEMENT_RESTITUTION')}
                          rules={[{ required: false }]}
                        >
                          <Input
                            placeholder={t(
                              'COMPANY_AGREEMENT_RESTITUTION_PLACEHOLDER',
                            )}
                            type="number"
                            maxLength={70}
                            style={{ height: '40px' }}
                            size="middle"
                            onChange={(e) =>
                              setFormValues({
                                ...formValues,
                                restitution: Number(e.target.value),
                              })
                            }
                          />
                        </FormItem>
                      </Col>
                      <Col xs={{ span: 12 }} md={{ span: 8 }} xl={{ span: 8 }}>
                        <FormItem
                          name="replacement_time"
                          label={t('COMPANY_AGREEMENT_REPLACEMENT_TIME')}
                          rules={[{ required: false }]}
                        >
                          <Input
                            placeholder={t(
                              'COMPANY_AGREEMENT_REPLACEMENT_TIME_PLACEHOLDER',
                            )}
                            type="number"
                            maxLength={70}
                            style={{ height: '40px' }}
                            size="middle"
                            onChange={(e) =>
                              setFormValues({
                                ...formValues,
                                replacement_time: Number(e.target.value),
                              })
                            }
                          />
                        </FormItem>
                      </Col>
                      <Col xs={{ span: 12 }} md={{ span: 8 }} xl={{ span: 8 }}>
                        <FormItem
                          label={t('JOB_CUSTOM_RESTITUTION_AGREEMENT')}
                          name="custom_restitution_agreement"
                          className="no-margin"
                        >
                          <Input
                            maxLength={70}
                            style={{ height: '40px' }}
                            size="middle"
                            onChange={(e) =>
                              setFormValues({
                                ...formValues,
                                custom_restitution_agreement: e.target.value,
                              })
                            }
                            type="text"
                          />
                        </FormItem>
                      </Col>
                      <Col xs={{ span: 12 }} md={{ span: 8 }} xl={{ span: 8 }}>
                        <FormItem
                          name="exclusivity_for_replacement"
                          label={t('COMPANY_AGREEMENT_EXCLUSIVITY_FOR_REPLACEMENT')}
                          rules={[{ required: false }]}
                        >
                          <SelectInput
                            placeholder={t(
                              'COMPANY_AGREEMENT_EXCLUSIVITY_FOR_REPLACEMENT_PLACEHOLDER',
                            )}
                            size="middle"
                            showSearch
                            optionFilterProp="children"
                            filterOption={true}
                            allowClear
                            dropdownMatchSelectWidth={false}
                            className="custom-select"
                            suffixIcon={<ArrowDownIcon />}
                            onChange={(value) =>
                              setFormValues({
                                ...formValues,
                                exclusivity_for_replacement:
                                  value === 'Yes' ? true : false,
                              })
                            }
                          >
                            {['Yes', 'No'].map((val, index) => (
                              <Select.Option value={val} key={index}>
                                {val}
                              </Select.Option>
                            ))}
                          </SelectInput>
                        </FormItem>
                      </Col>
                    </Row>
                    <SectionTitle>{t('RECRUITER_PAYMENT_TERMS')}</SectionTitle>
                    <Row gutter={10}>
                      <Col xs={{ span: 12 }} md={{ span: 8 }} xl={{ span: 8 }}>
                        <FormItem
                          name="payment_terms"
                          label={t('COMPANY_AGREEMENT_PAYMENT_TERMS')}
                          rules={[{ required: false }]}
                        >
                          <Input
                            placeholder={t(
                              'COMPANY_AGREEMENT_PAYMENT_TERMS_PLACEHOLDER',
                            )}
                            type="number"
                            maxLength={70}
                            style={{ height: '40px' }}
                            size="middle"
                            onChange={(e) =>
                              setFormValues({
                                ...formValues,
                                payment_terms: Number(e.target.value),
                              })
                            }
                          />
                        </FormItem>
                      </Col>
                      <Col xs={{ span: 12 }} md={{ span: 8 }} xl={{ span: 8 }}>
                        <FormItem
                          name="restitution_payment_terms"
                          label={t('COMPANY_AGREEMENT_RESTITUTION_PAYMENT_TERMS')}
                          rules={[{ required: false }]}
                        >
                          <Input
                            placeholder={t(
                              'COMPANY_AGREEMENT_RESTITUTION_PAYMENT_TERMS_PLACEHOLDER',
                            )}
                            type="number"
                            maxLength={70}
                            style={{ height: '40px' }}
                            size="middle"
                            onChange={(e) =>
                              setFormValues({
                                ...formValues,
                                restitution_payment_terms: Number(e.target.value),
                              })
                            }
                          />
                        </FormItem>
                      </Col>
                      <Col xs={{ span: 12 }} md={{ span: 8 }} xl={{ span: 8 }}>
                        <FormItem
                          label={t('JOB_PAYMENT_TERMS_START')}
                          name="payment_terms_start"
                          className="no-margin"
                        >
                          <SelectInput
                            size="middle"
                            optionFilterProp="children"
                            filterOption={true}
                            dropdownMatchSelectWidth={false}
                            className="custom-select"
                            allowClear
                            suffixIcon={<ArrowDownIcon />}
                            onChange={(value) =>
                              setFormValues({
                                ...formValues,
                                payment_terms_start: value,
                              })
                            }
                          >
                            {PAYMENT_TERM_START_TYPES.map((payment_term, index) => (
                              <Select.Option value={payment_term.value} key={index}>
                                {payment_term.name}
                              </Select.Option>
                            ))}
                          </SelectInput>
                        </FormItem>
                      </Col>
                    </Row>
                    <SectionTitle>{t('JOB_OTHER_INFORMATION')}</SectionTitle>
                    <Row gutter={10} style={{ marginTop: '1rem' }}>
                      <Col xs={{ span: 24 }} md={{ span: 24 }} xl={{ span: 24 }}>
                        <FormItem name="other_information" label={t("other_information")} className="no-margin">
                          <Textarea
                            autoSize={{ minRows: 3 }}
                            onChange={(e: any) =>
                              setFormValues({
                                ...formValues,
                                other_information: e.target.value,
                              })
                            }
                          />
                        </FormItem>
                      </Col>
                    </Row>
                    {transactionFeeEnabled && (
                      <TransactionFeeFormItem form={form} name="transaction_fee_to_company_percent" onChange={(value) => {
                        // !WB: I honestly don't understand why we have to be doing this...
                        setFormValues({ ...formValues, transaction_fee_to_company_percent: value ? parseInt(String(value)) : undefined });
                      }} />
                    )}
                  </>
                )}
              </TabPane>
              <TabPane tab={t('FEE_ET_TYPE', { employmentType: getEmploymentTypeNameById('freelance') })} key="2">
                <Row gutter={10}>
                  <Col xs={{ span: 12 }} md={{ span: 8 }} xl={{ span: 8 }}>
                    <FormItem
                      name="et_details_freelance"
                      label={t('COMPANY_AGREEMENT_ET_DETAILS', { employmentType: getEmploymentTypeNameById("freelance") })}
                      rules={[{ required: false }]}
                    >
                      <Input
                        type="text"
                        maxLength={70}
                        style={{ height: '40px' }}
                        size="middle"
                        onChange={(e) =>
                          setFormValues({
                            ...formValues,
                            et_details_freelance: e.target.value,
                          })
                        }
                      />
                    </FormItem>
                  </Col>
                </Row>
              </TabPane>
              <TabPane tab={t('FEE_ET_TYPE', { employmentType: getEmploymentTypeNameById('ctp') })} key="3">
                <Row gutter={10}>
                  <Col xs={{ span: 12 }} md={{ span: 8 }} xl={{ span: 8 }}>
                    <FormItem
                      name="et_details_ctp"
                      label={t('COMPANY_AGREEMENT_ET_DETAILS', { employmentType: getEmploymentTypeNameById("ctp") })}
                      rules={[{ required: false }]}
                    >
                      <Input
                        type="text"
                        maxLength={70}
                        style={{ height: '40px' }}
                        size="middle"
                        onChange={(e) =>
                          setFormValues({
                            ...formValues,
                            et_details_ctp: e.target.value,
                          })
                        }
                      />
                    </FormItem>
                  </Col>
                  <Col xs={{ span: 12 }} md={{ span: 8 }} xl={{ span: 8 }}>
                    <FormItem
                      name="et_cao_ctp"
                      label={t('COMPANY_AGREEMENT_ET_CAO', { employmentType: getEmploymentTypeNameById("ctp") })}
                      rules={[{ required: false }]}
                    >
                      <Input
                        type="text"
                        maxLength={70}
                        style={{ height: '40px' }}
                        size="middle"
                        onChange={(e) =>
                          setFormValues({
                            ...formValues,
                            et_cao_ctp: e.target.value,
                          })
                        }
                      />
                    </FormItem>
                  </Col>
                </Row>
                <Row gutter={10}>
                  <Col xs={{ span: 24 }} md={{ span: 16 }} xl={{ span: 16 }}>
                    <FormItem
                      name="et_gda_ctp"
                      label={t('COMPANY_AGREEMENT_ET_GDA', { employmentType: getEmploymentTypeNameById("ctp") })}
                      rules={[{ required: false }]}
                    >
                      <Textarea
                        autoSize={{ minRows: 3 }}
                        onChange={(e: any) =>
                          setFormValues({
                            ...formValues,
                            et_gda_ctp: e.target.value,
                          })
                        }
                      />
                    </FormItem>
                  </Col>
                </Row>
              </TabPane>
            </Tab>

            <SectionTitle>{t('RECRUITER_CONTRACT')}</SectionTitle>
            <Row gutter={10} style={{ marginTop: '1rem' }}>
              <>
                {recruiterAgreement.contract &&
                  file &&
                  !newFileUploading ? (
                  <div style={{ display: 'flex' }}>
                    <ActionWrapper>
                      <ActionLink
                        href={recruiterAgreement.contract as string}
                        target="_blank"
                        rel="noreferrer"
                      >
                        <DownloadIcon />
                        {t('COMPANY_AGREEMENT_DOWNLOAD_CONTRACT')}
                      </ActionLink>
                    </ActionWrapper>
                    <UploadAction>
                      <button
                        style={{
                          border: 'none',
                          display: 'flex',
                          backgroundColor: 'transparent',
                        }}
                      >
                        <DeleteFileIcon onClick={handleFileDelete} />
                      </button>
                    </UploadAction>
                  </div>
                ) : (
                  <Col
                    xs={{ span: 24 }}
                    sm={{ span: 12 }}
                    md={{ span: 24 }}
                    lg={{ span: 12 }}
                  >
                    <FormItem
                      label={t('COMPANY_AGREEMENT_CONTRACT')}
                      name="contract"
                    >
                      <StyledUploader
                        name="file"
                        showUploadList={false}
                        beforeUpload={handleUploadFile}
                        customRequest={() => void 0}
                      >
                        <FileUploader>
                          <FileName>{file}</FileName>
                          <UploadAction>
                            <Button type={'default'} size="small">
                              {t('CHOOSE_FILE')}
                            </Button>

                            {file && (
                              <DeleteFileIcon onClick={handleFileDelete} />
                            )}
                          </UploadAction>
                        </FileUploader>
                      </StyledUploader>
                    </FormItem>
                  </Col>
                )}
              </>
            </Row>
            <Row gutter={10} justify="end">
              <Col>
                <FormItem>
                  <Button
                    type="primary"
                    htmlType="button"
                    onClick={() => form.submit()}
                    style={{ height: '40px' }}
                    size="middle"
                    disabled={!submittable}
                  >
                    {t('SAVE')}
                  </Button>
                </FormItem>
              </Col>
            </Row>
          </SectionItem>
        </Space>
      </Form>
    </>
  );
};

export default RecruiterEditAgreement;
