import React, { useEffect } from 'react';
import ReactGa from 'react-ga4';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import Banner from '../components/auth/Banner';
import ClientOnboard from '../components/client/ClientOnboard';
import RecruiterTerms from '../components/recruiter/RecruiterTerms';
import { useAppSelector } from '../store/hooks';

const PageWrapper = styled.section`
  background: #0c1e2d;
  width: 100%;
  display: flex;
  min-height: 100vh;
  @media (max-width: 768px) {
    flex-direction: column;
    align-items: center;
  }
`;
const FormWrapper = styled.div`
  background: #fff;
  width: 61%;
  padding: 4.5rem 4rem;
  display: flex;
  flex-direction: column;
  @media (max-width: 1200px) {
    width: 70%;
  }
  @media (max-width: 768px) {
    width: 90%;
    padding: 3rem;
  }
  height: 100vh;
  overflow-y: auto;
`;

const Onboard = () => {
  const { user } = useAppSelector((state) => state.user);
  const [t] = useTranslation();
  useEffect(() => {
    ReactGa.pageview('/terms');
  }, []);
  return (
    <PageWrapper>
      <Banner size={'small'} title={t('GET_STARTED_LABEL')} clickable={false} />
      <FormWrapper>
        {user && user.role === 'client' && <ClientOnboard user={user} />}
        {user && user.role === 'recruiter' && (
          <RecruiterTerms user={user} isOnboard={true} />
        )}
      </FormWrapper>
    </PageWrapper>
  );
};

export default Onboard;
